import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Button, Input, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import ReCAPTCHA from 'react-google-recaptcha'

import { DarkGradientText, GradientText, Strings } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'
import { APP_SITE_KEY } from '@app/configs'

import Footer from '../footer/footer'
import LocationPage from '../about-us/location-page'

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.12
    }
  }
}

const childVariants = {
  hidden: { opacity: 0, x: -30 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }
}

const child2Variants = {
  hidden: { opacity: 0, x: 30 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }
}

const Support = () => {
  const isDark = useSelector(state => state.theme.isDark)
  const isMobile = useResponsive('down', 'sm')
  const isTab = useResponsive('between', 'sm', 'md')

  const recaptchaRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Stack
        alignItems={'center'}
        style={{
          background:
            '  linear-gradient(240deg, rgba(214, 229, 234, 0.5) 20%, white 0%, rgba(214, 229, 234, 0.5) 20%, white 40%)'
        }}
      >
        <Stack
          position={'relative'}
          alignItems={'center'}
          mt={10}
          mb={5}
          component={motion.div}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeOut' }}
        >
          <Stack position={'relative'} zIndex={0} top={2}>
            {isDark ? (
              <DarkGradientText text={Strings.page_title.letsTalk} />
            ) : (
              <GradientText text={Strings.page_title.letsTalk} />
            )}
          </Stack>

          <Stack position={'relative'} zIndex={1} top={isMobile ? -25 : -55}>
            <Typography
              color={isDark ? 'white' : 'black'}
              variant={isMobile ? 'h3' : 'h2'}
              fontWeight={'bold'}
            >
              {Strings.page_description.contactUs}
            </Typography>
          </Stack>

          <Stack position={'relative'} width={'60%'}>
            <Typography
              variant='body1'
              color={isDark ? 'lightgray' : 'gray'}
              textAlign={'center'}
            >
              {Strings.page_description.supportDetail}
            </Typography>
          </Stack>
        </Stack>

        {/* ----------form------------ */}
        <Stack
          width={'90vw'}
          bgcolor={'#FBFCFE'}
          alignItems={'center'}
          component={motion.div}
          variants={containerVariants}
          initial='hidden'
          animate={'visible'}
        >
          <Stack
            borderRadius={'20px'}
            boxShadow={'0px 2px 4px rgba(0, 0, 0, 0.1)'}
            px={9}
            py={5}
            my={6}
            bgcolor={'white'}
            width={isMobile ? '85vw' : '79vw'}
            direction={'row'}
            flexWrap={'wrap'}
            gap={3}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Stack
              width={isMobile || isTab ? '100%' : '45%'}
              minWidth={'30vw'}
              gap={2}
              component={motion.div}
              variants={childVariants}
            >
              <Typography color={isDark && 'white'}>
                {Strings.field.yourName}
              </Typography>
              <Input
                sx={{ color: isDark && 'white' }}
                fullWidth
                placeholder='Full Name'
              />
            </Stack>
            <Stack
              width={isMobile || isTab ? '100%' : '45%'}
              minWidth={'30vw'}
              gap={2}
              component={motion.div}
              variants={child2Variants}
            >
              <Typography color={isDark && 'white'}>
                {Strings.field.emailAddress}
              </Typography>
              <Input
                sx={{ color: isDark && 'white' }}
                fullWidth
                placeholder={Strings.field.emailAddress}
              />
            </Stack>
            <Stack
              width={isMobile || isTab ? '100%' : '45%'}
              minWidth={'30vw'}
              gap={2}
              component={motion.div}
              variants={childVariants}
            >
              <Typography color={isDark && 'white'}>
                {Strings.field.phone}
              </Typography>
              <Input
                sx={{ color: isDark && 'white' }}
                fullWidth
                placeholder='Phone Number'
              />
            </Stack>
            <Stack
              width={'100%'}
              gap={2}
              component={motion.div}
              variants={childVariants}
            >
              <Typography color={isDark && 'white'}>
                {Strings.field.message}
              </Typography>
              <Input
                sx={{ color: isDark && 'white' }}
                fullWidth
                multiline
                minRows={5}
                placeholder='Type Message'
              />
            </Stack>
            
            <ReCAPTCHA ref={recaptchaRef} sitekey={APP_SITE_KEY} />

            <Stack width={'100%'} mt={5} alignItems={'center'}>
              <Button
                style={{
                  width: '90%',
                  gap: 10,
                  backgroundColor: '#3AC1EF',
                  boxShadow: 'none',
                  fontWeight: 'normal',
                  borderRadius: '4px',
                  padding: isMobile ? '' : '8px 30px',
                  textTransform: 'initial'
                }}
                variant='contained'
              >
                {Strings.button.sendMessage}
              </Button>
            </Stack>
          </Stack>
        </Stack>

        {/* -------------location----------------- */}
        <LocationPage />
        {/* ---------------footer--------------- */}
        <Footer />
      </Stack>
    </>
  )
}

export default Support
