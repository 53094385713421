import img1 from '../images/companies/image1.png'
import img2 from '../images/companies/image2.png'
import img3 from '../images/companies/image3.png'
import infinity from '../images/companies/20infinity.png'
import femmes from '../images/companies/femmes.jpg'
import fragrancesy from '../images/companies/fragrancesy.png'
import groov from '../images/companies/groov.png'
import iso from '../images/companies/iso.png'
import nepmed from '../images/companies/nepmed.png'
import safegeneric from '../images/companies/safegeneric.png'
import vee from '../images/companies/vee.png'
import zplus from '../images/companies/zplus.png'

export const companiesData = [
  {
    id: 1,
    image: nepmed
  },
  {
    id: 2,
    image: safegeneric
  },
  {
    id: 3,
    image: groov
  },
  {
    id: 4,
    image: zplus
  },
  {
    id: 5,
    image: iso
  },
  {
    id: 6,
    image: img2
  },
  {
    id: 7,
    image: femmes
  },
  {
    id: 8,
    image: vee
  },
  {
    id: 9,
    image: fragrancesy
  },
  {
    id: 10,
    image: img1
  },
  {
    id: 11,
    image: img3
  },
  {
    id: 12,
    image: infinity
  }
]
