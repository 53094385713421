import React, { useRef, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import useResponsive from '@app/constants/use-responsive';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import './Carousel.css';

const Carousel = ({ data }) => {
  const swiperRef = useRef(null);
  const [swiperKey, setSwiperKey] = useState(0);
  const isMobile = useResponsive('down', 'md')

  useEffect(() => {
    setSwiperKey((prevKey) => prevKey + 1);
  }, [data]);


  return (
    <Stack
      width={'100%'}
      height={'100%'}
      position='relative'
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Stack
        className='portSwiper'
        width={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
        height={'100%'}
        position='relative'
      >
        <Stack width={'100%'}>
          <Swiper
            key={swiperKey}
            loop
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={isMobile ? '1' : '2'}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            spaceBetween={100}
            coverflowEffect={{
              rotate: 0,
              stretch: -10,
              depth: 200,
              modifier: 1,
              slideShadows: false,
            }}
            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
            className='mySwiper'
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {data?.map((slide, index) => (
              <SwiperSlide key={index}>
                <Stack
                  overflow='hidden'
                  position='relative'
                  zIndex={2}
                  alignItems='center'
                  justifyContent='center'
                  height='auto'
                  width='100%'
                  sx={{
                    height: '100%',
                    borderRadius: '10px',
                  }}
                >
                  <img
                    src={slide.image}
                    alt={slide.title}
                    style={{
                      height: '100%',
                      // objectFit: 'cover',
                    }}
                    onClick={ () => window.open(slide.link, '_blank')}
                  />
                </Stack>
              </SwiperSlide>
            ))}
          </Swiper>
        </Stack>
      </Stack>

      <Stack
        sx={{
          background: `linear-gradient(to left, white, rgba(255, 255, 255, 0.5) 5%, transparent 20%, transparent 80%, rgba(255, 255, 255, 0.5) 95%, white )`,
          width: '100%',
          height: '100%',
          position: isMobile ? '' :'absolute',
          zIndex: 1,
          pointerEvents: 'none',
        }}
      />
    </Stack>
  );
};

export default Carousel;
